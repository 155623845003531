@charset 'UTF-8';
/* Slider */
.slick-loading .slick-list {
  /* background: #fff url("ajax-loader.html") center center no-repeat; */
}

/* Icons */
@font-face {
  font-family: "slick";
  font-weight: normal;
  font-style: normal;

  src: url("../fonts/slick.eot");
  src: url("../fonts/slick.eot?#iefix") format("embedded-opentype"),
    url("../fonts/slick.woff") format("woff"),
    url("../fonts/slick.ttf") format("truetype"),
    url("../fonts/slick.svg#slick") format("svg");
}
/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;

  position: absolute;
  top: 40%;

  display: block;

  width: 64px;
  height: 64px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);

  cursor: pointer;

  color: #db070a;
  border: none;
  outline: none;
  z-index: 999999;
  border-radius: 60px;
  background-color: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: #db070a;
  outline: none;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 0;
}
.slick-prev.slick-disabled,
.slick-next.slick-disabled {
  opacity: 0.4 !important;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;

  opacity: 0.75;
  color: #db070a;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -60px;
}
[dir="rtl"] .slick-prev {
  right: -10px;
  left: auto;
}
.slick-prev {
  width: 64px;
  height: 64px;
  background-image: url("../images/arrows.png");
  background-repeat: no-repeat;
  background-position: left center;
}
[dir="rtl"] .slick-prev {
  width: 64px;
  height: 64px;
  background-image: url("../images/arrows.png");
  background-repeat: no-repeat;
  background-position: left center;
}

.slick-next {
  right: -60px;
}
[dir="rtl"] .slick-next {
  right: auto;
  left: -10px;
}
.slick-next {
  width: 64px;
  height: 64px;
  background-image: url("../images/arrows.png");
  background-repeat: no-repeat;
  background-position: right center;
}
[dir="rtl"] .slick-next {
  width: 64px;
  height: 64px;
  background-image: url("../images/arrows.png");
  background-repeat: no-repeat;
  background-position: right center;
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: 110px;

  display: block;

  width: 100%;
  padding: 0;
  margin: 0;
  z-index: 99999;
  list-style: none;

  text-align: center;
  transition: all 0.3s ease-in-out;
}
.slick-dots li {
  position: relative;

  display: inline-block;

  width: 13px;
  height: 13px;
  margin: 0 10px;
  padding: 0;

  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;

  display: block;

  width: 20px;
  height: 20px;
  padding: 5px;

  cursor: pointer;

  color: #909090;
  border: 0;
  outline: none;
  background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
  background: #fff;
  border-radius: 50%;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 0;
}
.slick-dots li button:before {
  font-size: 13px;
  line-height: 13px;

  position: absolute;
  top: 0;
  left: 0;

  width: 13px;
  height: 13px;

  content: "";
  text-align: center;

  opacity: 1;
  color: transparent;
  border: 2px solid #fff;
  border-radius: 50%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before {
  color: #fff;
  background: #fff;
  font-size: 35px;
  opacity: 1;
}

.iniciativas-center .slick-dots {
  bottom: -30px;
}
.iniciativas-center .slick-dots li.slick-active button:before {
  color: #0d9648;
  background: #0d9648;
}
.iniciativas-center .slick-dots li button {
  transition: all 0.3 ease-in-out;
  height: 14px;
  width: 14px;
  border: 2px solid #0d9648;
  border-radius: 50%;
}
.iniciativas-center .slick-dots li button:before {
  font-size: 10px;
  line-height: 10px;
  border: 2px solid #0d9648;
  height: 10px;
  width: 10px;
}

.iniciativas-center .slick-dots li button:hover,
.iniciativas-center .slick-dots li button:focus {
  background: #0d9648;
  height: 10px;
  width: 10px;
}
.iniciativas-center .slick-dots li {
  height: 10px;
  width: 10px;
  margin: 0 7px;
}
.slick-prev,
.slick-next {
  top: 104.2%;
}
.slick-next {
  width: 40px;
  height: 40px;
  background-color: #0d9648;
  right: 35%;
  bottom: 100px;
  border-radius: 100px;
  background-image: url("../images/arrows.png") !important;
  background-repeat: no-repeat !important;
  background-position: 110% !important;
}
.slick-prev {
  width: 40px;
  height: 40px;
  background-color: #0d9648;
  left: 35%;
  bottom: 100px;
  border-radius: 100px;
  background-image: url("../images/arrows.png") !important;
  background-repeat: no-repeat !important;
  background-position: 9px !important;
  z-index: 999999;
}

.post-midias-center .slick-dots {
  bottom: -30px;
}
.post-midias-center .slick-dots li.slick-active button:before {
  color: #0d9648;
  background: #0d9648;
}
.post-midias-center .slick-dots li button {
  transition: all 0.3 ease-in-out;
  height: 14px;
  width: 14px;
  border: 2px solid #0d9648;
  border-radius: 50%;
}
.post-midias-center .slick-dots li button:before {
  font-size: 10px;
  line-height: 10px;
  border: 2px solid #0d9648;
  height: 10px;
  width: 10px;
}

.post-midias-center .slick-dots li button:hover,
.post-midias-center .slick-dots li button:focus {
  background: #0d9648;
  height: 10px;
  width: 10px;
}
.post-midias-center .slick-dots li {
  height: 10px;
  width: 10px;
  margin: 0 7px;
}
.blog-center .slick-prev,
.blog-center .slick-next {
  top: 45%;
}
.blog-center .slick-prev {
  left: -25px;
}
.blog-center .slick-next {
  right: -25px;
}
.blog-center .slick-dots {
  bottom: -30px;
}
.blog-center .slick-dots li.slick-active button:before {
  color: #1e6588;
  background: #1e6588;
}
.blog-center .slick-dots li button {
  transition: all 0.3 ease-in-out;
  height: 14px;
  width: 14px;
  border: 2px solid #1e6588;
  border-radius: 50%;
}
.blog-center .slick-dots li button:before {
  font-size: 10px;
  line-height: 10px;
  border: 2px solid #1e6588;
  height: 10px;
  width: 10px;
}

.blog-center .slick-dots li button:hover,
.blog-center .slick-dots li button:focus {
  background: #1e6588;
  height: 10px;
  width: 10px;
}
.blog-center .slick-dots li {
  height: 10px;
  width: 10px;
  margin: 0 7px;
}
.blog-center .slick-next,
.blog-center .slick-prev {
  background: #1e6588;
}
.banner-container .slick-dots {
  bottom: 15px;
}
@media (max-width: 768px) {
  .slick-prev {
    left: 0%;
  }
  .slick-next {
    right: 0%;
  }
  .iniciativas-center .slick-dots li {
    margin: 0 5px;
  }
  .blog-center .slick-dots {
    bottom: -10px;
  }
  .blog-center .slick-next {
    right: -15px;
  }
  .blog-center .slick-prev {
    left: -15px;
  }
  .blog-center .slick-dots li.slick-active button:before {
    color: #1e6588;
    background: #1e6588;
  }
  .blog-center .slick-dots li button {
    transition: all 0.3 ease-in-out;
    height: 10px;
    width: 10px;
    border: 2px solid #1e6588;
    border-radius: 50%;
  }
  .blog-center .slick-dots li button:before {
    font-size: 5px;
    line-height: 5px;
    border: 2px solid #1e6588;
    height: 10px;
    width: 10px;
  }

  .blog-center .slick-dots li button:hover,
  .blog-center .slick-dots li button:focus {
    background: #1e6588;
    height: 5px;
    width: 5px;
  }
  .blog-center .slick-dots li {
    height: 5px;
    width: 5px;
    margin: 0 7px;
  }
  .blog-center .slick-next,
  .blog-center .slick-prev {
    background: #1e6588;
  }
}
@media (max-width: 320px) {
  .slick-prev {
    left: -5%;
  }
  .slick-next {
    right: -5%;
  }
  .iniciativas-center .slick-dots li {
    margin: 0 5px;
  }
}
