.flexWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

/* version 0 */
[version="0"] > * {
  width: calc(50% - 12px);
}
[version="0"] > *:nth-child(2) ~ * {
  margin-top: 1rem;
}
@media (max-width: 768px) {
  [version="0"] > * {
    width: 100%;
  }
  [version="0"] > *:nth-child(1) ~ * {
    margin-top: 1rem;
  }
}
/* version 0 */

/* version 1 */
[version="1"] > * {
  width: calc(25% - 12px);
}
[version="1"] > *:nth-child(4) ~ * {
  margin-top: 1rem;
}
@media (max-width: 768px) {
  [version="1"] > * {
    width: calc(50% - 12px);
  }
  [version="1"] > *:nth-child(2) ~ * {
    margin-top: 1rem;
  }
}
@media (max-width: 480px) {
  [version="1"] > * {
    width: 100%;
  }
  [version="1"] > *:nth-child(1) ~ * {
    margin-top: 1rem;
  }
}
/* version 1 */

/* version 2 */
[version="2"] > * {
  width: calc(33% - 10px);
}
[version="2"] > *:nth-child(3) ~ * {
  margin-top: 1rem;
}
@media (max-width: 768px) {
  [version="2"] > * {
    width: 100%;
  }
  [version="2"] > *:nth-child(1) ~ * {
    margin-top: 1rem;
  }
}
/* version 2 */

/* version 3 */
[version="3"] > * {
  width: calc(25% - 12px);
}
[version="3"] > *:nth-child(4) ~ * {
  margin-top: 1rem;
}
@media (max-width: 768px) {
  [version="3"] > * {
    width: calc(50% - 12px);
  }
  [version="3"] > *:nth-child(2) ~ * {
    margin-top: 1rem;
  }
}
/* version 3 */

/* version 4 */
[version="4"] > * {
  width: calc(50% - 12px);
}
@media (max-width: 768px) {
  [version="4"] > * {
    width: 100%;
  }
}
/* version 4 */

/* custom width */
/* version 5 */
[version="5"] > *:nth-child(1) {
  width: calc(41% - 12px);
}
[version="5"] > *:nth-child(2) {
  width: calc(58% - 12px);
}
[version="5"] > *:nth-child(2) ~ * {
  margin-top: 1rem;
}
@media (max-width: 768px) {
  [version="5"] > * {
    width: 100% !important;
  }
  [version="5"] > *:nth-child(1) ~ * {
    margin-top: 1rem;
  }
}
/* version 5 */

/* version 6 */
[version="6"] {
  justify-content: space-between;
}
[version="6"] > *:nth-child(1) {
  width: 170px;
}
[version="6"] > *:nth-child(2) {
  width: 250px;
}
[version="6"] > *:nth-child(3) {
  width: 456px;
}
[version="6"] > *:nth-child(4) {
  width: 170px;
}
[version="6"] > *:nth-child(4) ~ * {
  margin-top: 1rem;
}
@media (max-width: 768px) {
  [version="6"] > * {
    width: 100% !important;
  }
  [version="6"] > *:nth-child(1) ~ * {
    margin-top: 1rem;
  }
}
/* version 6 */

/* version 7 */
[version="7"] > * {
  width: calc(33% - 10px);
}
[version="7"] > *:nth-child(3) ~ * {
  margin-top: 1rem;
}
@media (max-width: 768px) {
  [version="7"] > * {
    width: calc(33% - 6px);
  }
}
/* version 7 */

/* version 8 */
[version="8"] > *:nth-child(1) {
  width: calc(58% - 12px);
}
[version="8"] > *:nth-child(2) {
  width: calc(42% - 12px);
}
[version="8"] > *:nth-child(2) ~ * {
  margin-top: 1rem;
}
@media (max-width: 768px) {
  [version="8"] > * {
    width: 100% !important;
  }
  [version="8"] > *:nth-child(1) ~ * {
    margin-top: 1rem;
  }
}
/* version 8 */
