@font-face {
  font-family: "Avenir Next Condensed";
  src: url("../fonts/AvenirNextCondensed-Medium.eot");
  src: url("../fonts/AvenirNextCondensed-Medium.woff2") format("woff2"),
    url("../fonts/AvenirNextCondensed-Medium.woff") format("woff"),
    url("../fonts/AvenirNextCondensed-Medium.svg#AvenirNextCondensed-Medium")
      format("svg");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;1,200;1,300;1,400;1,600&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&amp;display=swap");

* {
  margin: 0;
  padding: 0;
  font-family: "Source Sans Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
body {
  background-color: #f7f7f7;
}
li {
  list-style-type: none;
}
.container {
  max-width: 1128px;
  margin-left: auto;
  margin-right: auto;
}
.retornarImg {
  width: 200px;
}
.btn {
  margin: 0 auto;
  border-radius: 5px;
  font-size: 20px;
  font-weight: normal;
  text-align: center;
  text-decoration: none;
  color: #002b63;
  height: 65px;
  line-height: 65px;
  background: rgb(255, 212, 0);
  background: linear-gradient(
    245deg,
    rgba(255, 212, 0, 1) 0%,
    rgba(251, 162, 0, 1) 100%
  );
  -webkit-box-shadow: 3px 0px 6px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 3px 0px 6px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 3px 0px 6px 0px rgba(0, 0, 0, 0.16);
}
.btn-gradient-amarelo {
  float: right;
  display: inline-block;
  width: 465px;
  height: 65px;
  border-radius: 5px;
  font-size: 20px;
  font-weight: 600;
  color: #002b63;
  text-align: center;
  line-height: 65px;
  text-decoration: none;
  background: linear-gradient(-70deg, #ffd400, #fba200);
  margin: 0 auto;
}
.btn-gradient {
  margin: 30px 0 0;
  display: block;
  width: 355px;
  height: 75px;
  background: linear-gradient(-70deg, #398cb5, #165f84);
  border-radius: 5px;
  font-size: 29px;
  font-weight: bold;
  color: #fff;
  line-height: 73px;
  text-decoration: none;
  text-align: center;
}
.btn-sem-fundo-verde {
  display: block;
  width: 400px;
  height: 65px;
  border: 1px solid #009d42;
  border-radius: 8px;
  font-size: 20px;
  font-weight: 600;
  color: #0b793a;
  text-align: center;
  line-height: 60px;
  text-transform: uppercase;
  text-decoration: none;
  clear: both;
}
.subtitulo-verde {
  font-size: 40px;
  font-weight: 600;
  color: #0d9648;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.subtitulo-branco {
  font-size: 40px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 15px;
}
.btn-iniciativas {
  display: block;
  margin: 0 auto 35px;
  text-align: center;
  width: 130px;
  line-height: 40px;
  border: 1px solid #0d9648;
  border-radius: 8px;
  font-size: 16px;
  font-family: "Titillium Web", sans-serif;
  font-weight: 600;
  color: #0d9648;
  text-decoration: none;
  line-height: 35px;
}
.wave-blue {
  background-image: url(../images/wave-blue.png);
  background-repeat: no-repeat;
  background-position: center 5px;
  background-size: cover;
  width: 100%;
  height: 270px;
}

.separador {
  display: block;
  width: 100%;
  height: 1px;
  background: #707070;
  opacity: 0.3;
  margin: 60px auto;
}
/* Index */
/* Header */
.header {
  position: relative;
  background-color: #fff;
  border-bottom: 4px solid #0d9648;
}
.header::before {
  content: "";
  display: block;
  position: absolute;
  margin-right: 10px;
  width: 0;
  height: 0;
  bottom: -12px;
  left: calc(50% - 5px);
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #0d9648;
  z-index: 9999999;
}
.header .container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.header-logo {
  font-size: 0;
  transition: all 0.3s ease-in-out;
}
.header-logo:hover {
  opacity: 0.6;
}
.header nav ul {
  display: flex;
}
.header nav ul li {
  transition: all 0.3s ease-in-out;
}
.header nav ul li a {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: #5f5f5f;
  text-transform: uppercase;
  text-decoration: none;
  padding: 0px 20px;
  height: 100px;
}
.header nav ul li a:hover {
  color: #0d9648;
}

.header .menu-1 {
  margin-left: auto;
}

.header .menu-2 li {
  position: relative;
}

.header .menu-2 li::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 1px;
  background: rgba(112, 112, 112, 0.2);
  margin: 0px;
}
.header .menu-2 li:hover {
  background: #0d9648;
}
.header .menu-2 li a {
  color: #0d9648;
}
.header .menu-2 li:hover a {
  color: #fff;
}
.header .menu-2 svg {
  margin-right: 10px;
}
.header .menu-2 li:hover svg path {
  fill: #fff;
}

/* Banner */
.banner {
  background-image: url(../images/bg3-index.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 640px;
}

/* Seja */
.seja {
  background: url(../images/bg-sejasocio-index.jpg) no-repeat right;
  background-size: cover;
  width: 100%;
  padding: 25px 0;
}
.seja.pix {
  background-image: url(../images/bg-pix.jpg);
  background-repeat: no-repeat;
  background-position: left center;
  background-size: cover;
  min-height: 166px;
}
@media (max-width: 768px) {
  .seja.pix {
    background-image: url(../images/bg-pix-mobile.jpg);
  }
}
.seja .seja-content h2 {
  display: inline-block;
  font-size: 35px;
  font-weight: 600;
  color: #002b63;
  text-transform: uppercase;
}
.seja .seja-content h2 strong {
  font-weight: 900;
}
.seja .seja-content p {
  display: inline-block;
  font-size: 18px;
  font-weight: 400;
  color: #002b63;
  line-height: 30px;
  margin-top: 10px;
}
.seja .seja-content > a {
  display: inline-block;
  font-weight: 900;
  color: #007432;
}

.seja .seja-content .btn-gradient {
  float: right;
  margin-top: 30px;
  width: 385px;
  height: 65px;
  line-height: 60px;
}

.seja .seja-content .btn-gradient p {
  pointer-events: none;
  font-size: 25px;
  line-height: 20px;
  font-weight: 400;
  color: #fff;
  margin: 0;
  text-align: center;
  bottom: 0;
  left: 0;
}
.seja .seja-content .btn-gradient span {
  font-size: 30px;
  line-height: 20px;
  font-weight: 700;
}
/* Pix */
.pix-content {
  display: flex;
  align-items: center;
  justify-content: right;
  gap: 70px;
}
.pix-content::after {
  display: none !important;
}
.pix-content .left h2 {
  font-weight: 400;
  font-size: 35px;
  line-height: 48px;
  margin-bottom: 12px;
  color: #002b63;
}
.pix-content .left p {
  color: #002b63;
  font-weight: 400;
  font-size: 18px;
  line-height: 25px;
}
.pix-content .left p br {
  display: none;
}
.pix-content .right p {
  color: #002b63;
  font-weight: 400;
  font-size: 28px;
  line-height: 38px;
}
.btn-gradient-secondary {
  display: none;
}
@media (max-width: 768px) {
  .seja.pix {
    padding: 20px 0px;
  }
  .pix-content {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }
  .pix-content .left h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 8px;
  }
  .pix-content .left p {
    font-size: 14px;
    line-height: 16px;
  }
  .pix-content .left p br {
    display: block;
  }
  .pix-content .right {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .pix-content .right p {
    font-size: 16px;
    line-height: 20px;
  }
  .btn-gradient-secondary {
    display: block;
    padding: 0px 8px;
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;
    color: #ffffff;
    width: 100%;
    max-width: 116px;
    height: 48px;
    border: none;
    background: linear-gradient(180deg, #398cb5 0%, #095397 100%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
  }
}
@media (max-width: 320px) {
  .pix-content .right p {
    font-size: 14px;
  }
}
/* Projeto */
.projeto {
  background: #0d9648;
  width: 100%;
  height: 970px;
  padding-top: 45px;
}

.projeto .projeto-sobre {
  display: block;
}
.projeto .projeto-foto-container {
  background: #fff;
  width: 450px;
  padding: 25px 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  transform: rotate(-2deg);
}
.projeto .projeto-foto-container h3 {
  text-align: center;
  margin-top: 10px;
  font-size: 25px;
  font-weight: bold;
  color: #176186;
}
.projeto .projeto-itens:nth-child(2) {
  margin-top: -15px;
  padding-left: 20px;
}
.projeto .projeto-itens:nth-child(2) h3 {
  font-size: 25px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 15px;
}
.projeto .projeto-itens:nth-child(2) p {
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  line-height: 30px;
  margin-bottom: 25px;
}
.missao {
  padding-top: 40px;
}
.missao .missao-itens {
  display: flex;
  flex-direction: column;
  background: #096c34;
  border-radius: 20px;
  height: 450px;
}
.missao .missao-itens h3 {
  font-size: 25px;
  font-weight: 600;
  color: #ffb000;
  margin-bottom: 10px;
  padding: 20px 30px;
}
.missao .missao-itens p {
  padding: 0 20px 0 30px;
  color: #fff;
}
.missao .missao-itens img {
  margin-top: auto;
  width: 100%;
  border-radius: 0 0 20px 20px;
  -webkit-filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.2));
}
/* Região */
.regiao {
  padding-top: 60px;
}
.regiao .regiao-itens:nth-child(1) {
  max-width: 496px;
}

.regiao .regiao-itens:nth-child(1) p {
  font-size: 18px;
  line-height: 30px;
  font-weight: 400;
  color: #5f5f5f;
  margin-bottom: 20px;
}
.regiao .regiao-itens:nth-child(2) p {
  font-size: 11px;
  font-weight: 700;
  text-align: right;
  color: #5f5f5f;
}
.regiao .regiao-itens:nth-child(2) .regiao-itens-foto {
  background: url(../images/foto-regiao.jpg) no-repeat;
  width: 100%;
  height: 555px;
  padding-left: 8px;
  border-radius: 10px;
  box-shadow: 0 10px 20px 3px rgba(0, 0, 0, 0.2);
}
.regiao .regiao-itens:nth-child(2) .regiao-itens-foto h3 {
  display: block;
  font-size: 75px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: -60px;
}
.regiao .regiao-itens:nth-child(2) .regiao-itens-foto h4 {
  float: left;
  font-size: 75px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  margin-top: 50px;
  line-height: 40px;
}
.regiao .regiao-itens:nth-child(2) .regiao-itens-foto h5 {
  display: inline-block;
  font-size: 138px;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
}
/* Vaquinha */
.vaquinha.desk {
  background: url(../images/bg-vaquinha.jpg) no-repeat right;
  background-size: cover;
  margin-top: 90px;
  padding: 50px 0;
}
.vaquinha.mobile {
  display: none;
}
.vaquinha h2 {
  display: inline-block;
  font-size: 40px;
  font-weight: 400;
  color: #fff;
}
.vaquinha h2 strong {
  text-transform: uppercase;
}
.vaquinha .btn-gradient-amarelo {
  max-width: 465px;
}

/* Blog */

.blog {
  padding: 50px 0 70px 0;
}

.blog .card {
  padding: 20px;
}
.actions__card {
  border-radius: 20px;
  background-color: #003652;
  margin-bottom: 20px;
  -webkit-box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.21);
  -moz-box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.21);
  box-shadow: 0px 0px 25px 0px rgba(0, 0, 0, 0.21);
  text-decoration: none;
  display: block;
}

.actions__card h3 {
  padding: 25px 20px;
  font-size: 20px;
  color: #ffb000;
}

.actions__card img {
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-end;
  border-radius: 15px;
}

/* Page Sócio Transformador */
.socio {
  background: #f7f7f7;
}
.socio-transformador {
  margin-top: 60px;
}
.socio-transformador p {
  font-size: 18px;
  line-height: 30px;
  color: #5f5f5f;
  margin-bottom: 30px;
}
.socio-transformador div:nth-child(2) {
  display: block;
  text-align: center;
}

.alcancado {
  margin-top: 60px;
}
.alcancado .center {
  margin-top: 30px;
}
.alcancado .box-content {
  background: url(../images/bg-card-alcancado.jpg) no-repeat center;
  background-size: cover;
  height: 265px;
  width: 92%;
  border-radius: 20px;
  padding: 30px 20px 0 20px;
  filter: drop-shadow(0 3px 4px rgba(0, 0, 0, 0.25));
}
.barra-prog {
  margin-top: 30px;
}
.barra-prog p {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  color: #5f5f5f;
  width: 90%;
  margin-bottom: 20px;
}
.barra-prog .bg-barra {
  display: block;
  width: 92%;
  height: 8px;
  background: #d6d6d6;
  border-radius: 10px;
}
.barra-prog .bg-barra .barra {
  display: block;
  width: 75%;
  height: 8px;
  background: linear-gradient(to left, #0d9648 50%, #8cc341);
  border-radius: 10px;
}

/* Iniciativas */
.iniciativas {
  margin-top: 60px;
  margin-bottom: 100px;
}
.iniciativas-center {
  margin-top: 30px;
}
.iniciativas-box {
  padding: 20px;
}
.iniciativas-box-content {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
  padding-top: 20px;
  user-select: none;
}
.iniciativas-box-content i {
  display: block;
}
.iniciativas-box-content i img {
  display: block;
  margin: 0 auto 24px;
}
.iniciativas-box-content p {
  display: block;
  font-size: 16px;
  font-family: "Roboto";
  font-weight: 400;
  color: #141414;
  height: 120px;
  line-height: 1;
  padding: 0 15px;
}
.iniciativas-box-content > img {
  display: block;
  width: 100%;
  margin-top: auto;
}
/* Separador Seja Socio */
.seja-socio {
  background: url(../images/bg-seja-socio.jpg) no-repeat center;
  background-size: cover;
  padding: 35px 0;
  width: 100%;
  text-align: center;
}
.seja-socio-content-mobile {
  display: none;
}
.seja-socio h2 {
  font-size: 40px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 20px;
}
.seja-socio p {
  font-size: 18px;
  font-family: "Roboto";
  font-weight: 400;
  color: #fff;
  width: 50%;
  margin: 0 auto 25px auto;
}
.seja-socio .btn-gradient-amarelo {
  float: none;
  display: block;
  margin: 0 auto;
  width: 340px;
  height: 60px;
  line-height: 60px;
  font-weight: bold;
  font-family: "Titillium Web", sans-serif;
}
.seja-socio span {
  display: block;
  margin-top: 10px;
  font-size: 16px;
  font-weight: bold;
  font-family: "Roboto";
  color: #fed200;
}

/* Posts */
.post-midias {
  margin-top: 60px;
  margin-bottom: 60px;
}
.post-midias-box {
  padding: 20px;
}
.post-midias-box-content {
  text-align: center;
}
.post-midias-box-content img {
  display: block;
  margin: 0 auto;
  border-radius: 50%;
}
.post-midias-box-content h3 {
  font-size: 22px;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
  color: #5b5b5b;
  margin: 25px auto 15px auto;
}
.post-midias-box-content p {
  font-size: 20px;
  font-weight: 400;
  font-family: "Abel", sans-serif;
  color: #2e2e2e;
  margin-bottom: 32px;
}
.post-midias-box-content span {
  font-size: 17px;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
  color: #5b5b5b;
}
.share {
  display: block;
  text-align: center;
  margin-top: 60px;
}
.share h2 {
  font-size: 45px;
  font-weight: 400;
  font-family: "Abel", sans-serif;
  color: #7f7f7f;
  margin-bottom: 40px;
}
.share ul li {
  display: inline-block;
  list-style: none;
  margin: 0 10px;
  border-radius: 50%;
}
/* Parceiros */
.parceiros {
  background: #1e6588;
}
.parceiros ul li {
  display: inline-block;
  list-style: none;
  margin: 0 20px;
  vertical-align: middle;
}
.parceiros ul li:nth-child(1) {
  margin-left: 0;
}
/* Contato */
.contato {
  background: #1e6588;
  padding-top: 140px;
}
.contato form {
  padding-right: 88px;
}
.contato form input,
.contato form textarea {
  display: block;
  width: 100%;
  height: 70px;
  border: none;
  border-radius: 15px;
  background: #003652;
  padding-left: 25px;
  font-size: 18px;
  font-weight: 400;
  color: #ffffff;
}
.contato form textarea {
  padding: 25px;
  height: 215px;
  resize: none;
}
.contato form input::placeholder,
.contato form textarea::placeholder {
  font-size: 18px;
  font-weight: 400;
  color: #ffffff;
}
.contato button {
  float: none;
  display: block;
  width: 100%;
  height: 50px;
  border: none;
  line-height: 50px;
  cursor: pointer;
}
.contato iframe {
  width: 100%;
  height: 635px;
}
.ifrane_container {
  max-width: 560px;
}
.iframe_wrapper {
  position: relative;
  padding-top: 56.25%;
  width: 100%;
}
.ifrane_container iframe {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  height: 100%;
}
/* Footer */
footer {
  background: #003652;
  padding: 80px 0 0;
}
footer .col-2::before,
footer .col-3::before {
  float: left;
  content: "";
  width: 1px;
  height: 210px;
  background: #fff;
  margin-right: 20px;
}
footer .col-2 ul li,
footer .col-3 ul li,
footer .col-4 ul li {
  list-style: none;
  margin-bottom: 20px;
}
footer .col-2 ul li a {
  font-size: 20px;
  color: #fff;
  text-decoration: none;
}
footer .col-3 h3 {
  font-size: 20px;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 20px;
}
footer .col-3 ul li i {
  display: inline-block;
  margin-right: 25px;
  vertical-align: middle;
}
footer .col-3 ul li span {
  display: inline-block;
  width: 70%;
  vertical-align: middle;
  font-size: 16px;
  color: #fff;
}
footer .col-3 ul li:nth-child(2) span {
  margin-left: -6px;
}
footer .col-4 ul li {
  display: inline-block;
  margin: 0 5px;
}
/* Copy */
.copy {
  background: #00263a;
  margin-top: 70px;
  padding: 30px 0;
  text-align: center;
}
.copy h6 {
  font-size: 15px;
  font-weight: 400;
  color: #fff;
  margin-bottom: 10px;
}
.copy p {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1.5px;
  color: rgba(255, 255, 255, 0.5);
}
.copy a {
  font-weight: 600;
  color: rgba(255, 255, 255, 0.5);
  text-decoration: none;
}
/* Iniciativas */
.page-iniciativas {
  margin: 0;
  background: #f7f7f7;
}
.herois-portelinha {
  margin-top: 80px;
}
.herois-portelinha h2 {
  font-size: 30px;
}
.herois-portelinha p {
  font-size: 18px;
  font-weight: 400;
  color: #5f5f5f;
  line-height: 30px;
  margin-bottom: 25px;
  padding-right: 40px;
}
.herois-portelinha iframe {
  border-radius: 10px;
}
.acoes {
  padding-bottom: 70px;
}
.acoes h2 {
  font-size: 40px;
}
.acoes p {
  font-size: 18px;
  line-height: 30px;
  color: #5f5f5f;
  margin-bottom: 100px;
  padding-right: 40px;
}

.acoes img {
  display: block;
  width: 565px;
  height: 385px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}
.seja-socio .btn-sem-fundo {
  display: block;
  width: 340px;
  height: 60px;
  border: 1px solid #ffcb00;
  border-radius: 8px;
  font-size: 20px;
  font-weight: 600;
  font-family: "Titillium Web", sans-serif;
  text-align: center;
  text-decoration: none;
  line-height: 60px;
  color: #fecc00;
  margin: 0 auto;
}
/* Caixote do Saber */
.detalhes-iniciativa {
  padding: 70px 0px;
  background: #f7f7f7;
}
.banner-detalhes-iniciativa-mobile {
  display: none;
}
.detalhes-iniciativa-content p {
  font-size: 18px;
  line-height: 30px;
  font-weight: 400;
  color: #5f5f5f;
  padding-right: 40px;
  margin-bottom: 25px;
}
.detalhes-iniciativa-content img {
  display: block;
  width: 100%;
  border-radius: 10px;
  filter: drop-shadow(0 5px 15px rgba(0, 0, 0, 0.3));
}
.detalhes-socio {
  padding-top: 70px;
  background: #f7f7f7;
}
.banner-detalhes-socio-mobile {
  display: none;
}
.detalhes-socio-content p {
  font-size: 18px;
  line-height: 30px;
  font-weight: 400;
  color: #5f5f5f;
  margin-bottom: 25px;
}
.detalhes-socio-content img {
  display: block;
  width: 300px;
  height: 300px;
  margin: 0 auto;
}
.galeria {
  margin-top: 60px;
}
.galeria h2 {
  margin-bottom: 40px;
}

/* .galeria-center {
  width: 564px;
} */
.galeria-center div {
  margin: 0;
}
.galeria-box {
  padding-right: 20px;
}
.galeria-box img {
  width: 100%;
}
.galeria .btn-sem-fundo-verde {
  margin-top: 60px;
}
/* secondary-header */
.secondary-header {
  background-color: #fff;
  border-bottom: 4px solid #0d9648;
}
.secondary-header .container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.secondary-header .container::after {
  display: none;
}
.secondary-header .header-title {
  font-size: 18px;
  color: #0d9648;
  text-transform: uppercase;
  margin: 0px 45px 0px auto;
}
.secondary-header .header-close {
  display: flex;
  align-items: center;
  height: 100px;
  font-size: 0;
  padding: 0px 45px;
  transition: 0.3s;
  border-left: 1px solid rgba(112, 112, 112, 0.2);
}
.secondary-header .header-close:focus,
.secondary-header .header-close:hover {
  background: #0d9648;
}
.secondary-header .header-close line {
  transition: 0.3s;
}
.secondary-header .header-close:focus line,
.secondary-header .header-close:hover line {
  stroke: #fff;
}
/* Cadastro */
.cadastro {
  background: #f7f7f7;
}
.form-cadastro {
  margin-top: 70px;
}
.form-cadastro h2 {
  font-size: 18px;
  font-weight: 400;
  color: #5f5f5f;
  margin-bottom: 24px;
}
.form-cadastro form label {
  display: inline-block;
  font-size: 18px;
  font-weight: 700;
  color: #5f5f5f;
  margin-bottom: 12px;
}
.form-cadastro form input,
.form-cadastro form select {
  display: block;
  width: 100%;
  height: 48px;
  border: 1px solid #495878;
  border-radius: 4px;
  padding: 0px 10px;
  font-size: 18px;
  font-weight: 400;
  color: #626262;
  transition: all 0.3s ease-in-out;
}
.form-cadastro form select:focus,
.form-cadastro form input:focus {
  outline: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}
.form-cadastro form select option:first-child {
  display: none;
}
.form-cadastro .form-title {
  color: #0d9648;
  font-size: 1.125rem;
  margin-bottom: 16px;
  text-transform: uppercase;
}
.form-cadastro .flex-wrapper-half {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.form-cadastro .flex-wrapper-half > * {
  width: calc(50% - 8px);
}
.form-cadastro .flex-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.form-cadastro .flex-wrapper > * {
  width: calc(25% - 12px);
}
@media (max-width: 768px) {
  .form-cadastro .flex-wrapper {
    justify-content: flex-start;
  }
  .form-cadastro .flex-wrapper > *:nth-child(1),
  .form-cadastro .flex-wrapper > *:nth-child(2) {
    width: calc(50% - 8px);
  }
  .form-cadastro .flex-wrapper > *:nth-child(2) {
    margin-left: auto;
  }
  .form-cadastro .flex-wrapper > *:nth-child(3) {
    width: 130px;
    margin-right: 20px;
  }
}
.form-cadastro .maturity {
  margin-top: 20px;
}
.form-cadastro .maturity h3 {
  font-size: 18px;
  font-weight: 400;
  color: #626262;
  margin-bottom: 15px;
}
.form-cadastro .maturity ul {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(5, 1fr);
  row-gap: 0.5rem;
  column-gap: 0.5rem;
  width: min-content;
  margin-bottom: 16px;
}
.form-cadastro .maturity ul li {
  margin-right: auto;
  list-style: none;
}
.form-cadastro .maturity input[type="radio"] {
  display: none;
}
.form-cadastro .maturity label {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  color: rgba(102, 102, 102, 0.6);
  font-size: 20px;
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.4s;
  background-color: #ffffff;
  border: 1px solid rgba(116, 116, 116, 0.5);
  user-select: none;
  margin-bottom: 0px;
}
.form-cadastro .maturity label:hover {
  color: #ffffff;
  background-color: #0d9648;
  border-color: #0d9648;
}
.form-cadastro .maturity input:checked + label {
  color: #ffffff;
  background-color: #0d9648;
  border-color: #0d9648;
}
.form-cadastro .maturity label:hover {
  opacity: 0.75;
}
.form-cadastro .donation {
  margin-top: 20px;
  margin-bottom: 24px;
}
.form-cadastro .donation h3 {
  font-size: 18px;
  font-weight: 700;
  color: #626262;
  margin-bottom: 15px;
}
.form-cadastro .donation ul {
  margin-bottom: 16px;
}
.form-cadastro .donation ul li {
  list-style: none;
  margin-bottom: 8px;
}
.form-cadastro .radio label {
  display: flex;
  width: fit-content;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  align-items: center;
  cursor: pointer;
  margin-bottom: 24px;
}
.form-cadastro .radio strong {
  margin-right: 12px;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  border: 1px solid;
  background-color: transparent;
  border-color: #0d9648;
  pointer-events: none;
  user-select: none;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-cadastro .radio strong::after {
  content: "";
  display: block;
  background-color: #0d9648;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  transform: scale(0);
  transition: 0.3s;
}
.form-cadastro .radio label:hover strong,
.form-cadastro .radio label:focus strong {
  border-color: #087538;
}
.form-cadastro .radio input[type="radio"] {
  display: none;
}
.form-cadastro .radio input[type="radio"]:checked ~ strong::after {
  transform: scale(1);
}
.form-cadastro .radio span {
  position: relative;
  line-height: 16px;
  font-weight: normal;
}
.form-cadastro .donation input[type="radio"] {
  display: none;
}
.form-cadastro .donation label {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 312px;
  height: 48px;
  line-height: 48px;
  color: #5f5f5f;
  font-size: 20px;
  font-weight: 700;
  border-radius: 4px;
  cursor: pointer;
  transition: 0.4s;
  background-color: #ffffff;
  border: 1px solid #5f5f5f;
  user-select: none;
  margin-bottom: 0px;
  padding: 0px 12px;
}
@media (max-width: 768px) {
  .form-cadastro .donation label {
    max-width: 100%;
  }
}
.form-cadastro .donation label:hover {
  color: #ffffff;
  background-color: #0d9648;
  border-color: #0d9648;
}
.form-cadastro .donation input:checked + label {
  color: #ffffff;
  background-color: #0d9648;
  border-color: #0d9648;
}
.form-cadastro .donation label:hover {
  opacity: 0.75;
}
.form-cadastro .donation label .marker {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background: #ffffff;
  border: 1px solid #5f5f5f;
  border-radius: 4px;
  margin-right: 20px;
}
.form-cadastro .donation label .marker svg {
  opacity: 0;
  transition: 0.3s;
}
.form-cadastro .donation input:checked + label .marker svg {
  opacity: 1;
}
.form-cadastro .link-input {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  right: 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  height: 18px;
  text-decoration-line: underline;
  color: #5f5f5f;
}
.form-cadastro .help-message {
  position: relative;
  display: inline-block;
  text-decoration: none;
  font-size: 16px;
  color: #2ba9dd;
  font-weight: 400;
  margin-bottom: 0;
}
.form-cadastro .help-message span {
  position: absolute;
  top: 25px;
  display: block;
  width: 160px;
  padding: 5px 8px;
  color: #ffffff;
  border-radius: 4px;
  font-size: 0.75rem;
  background-color: #0d9648;
  opacity: 0;
  pointer-events: none;
  transition: 0.3s;
}
.form-cadastro .help-message.active span {
  opacity: 1;
  pointer-events: all;
}
.form-cadastro form button {
  display: block;
  margin: 60px 0;
  width: 100%;
  height: 65px;
  border: none;
  border-radius: 8px;
  background: #0d9648;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
  font-size: 20px;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
}
.form-cadastro form button:disabled {
  background: #bbb;
  cursor: not-allowed;
}
/* Sucesso */
.sucesso {
  margin-top: 70px;
}
.sucesso > div > p {
  font-size: 18px;
  color: #5f5f5f;
  margin-bottom: 30px;
  line-height: 30px;
}
.detalhes {
  display: block;
  margin-bottom: 35px;
}
.detalhes-content div {
  display: block;
  background: #fff;
  width: 47%;
  border-radius: 15px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
  padding: 15px 20px 25px;
}
.detalhes-content div p {
  font-size: 18px;
  font-weight: 400;
  color: #2e2e2e;
  font-family: "Roboto", sans-serif;
  line-height: 20px;
}
.detalhes-content div a {
  display: block;
  font-size: 18px;
  font-weight: 400;
  color: #309ed9;
  font-family: "Roboto", sans-serif;
  text-decoration: none;
  margin-top: 10px;
}
.credencial {
  margin-top: 10px;
  padding-bottom: 80px;
}
.credencial-content {
  background: url(../images/bg-credencial.png) no-repeat center;
  background-size: cover;
  width: 100%;
  height: 530px;
  text-align: center;
  padding-top: 30px;
}
.credencial-content h3 {
  font-size: 26px;
  text-transform: uppercase;
  font-family: "Titillium Web";
  margin-bottom: 10px;
}
.credencial-content > p {
  font-size: 21px;
  color: #2e2e2e;
  font-family: "Titillium Web";
}
.btn-cancelar {
  display: block;
  width: 100%;
  height: 50px;
  background: transparent;
  border: 1px solid #00527b;
  border-radius: 8px;
  text-align: center;
  line-height: 50px;
  font-size: 12px;
  font-weight: bold;
  color: #00527b;
  text-decoration: none;
  font-family: "Titillium Web";
}
.credencial-share p {
  font-size: 18px;
  color: #5f5f5f;
  width: 50%;
  line-height: 30px;
  margin-bottom: 82px;
}
.credencial-share h3 {
  font-size: 45px;
  color: #7f7f7f;
  font-family: "Abel", sans-serif;
  font-weight: 300;
  margin-bottom: 40px;
}
.credencial-share ul li {
  list-style: none;
  display: inline-block;
  margin-right: 20px;
}
/* Login */
.login {
  min-height: 100vh;
  background: #f7f7f7;
}

.login-content {
  margin-top: 60px;
}
.login-content form {
  width: 100%;
  max-width: 360px;
  margin: 60px auto auto;
}
.login-content h2 {
  font-size: 18px;
  font-weight: 400;
  color: #5f5f5f;
  text-align: center;
}
.form-row {
  position: relative;
}
.input-wrapper {
  margin-bottom: 16px;
}
.login-content form label {
  display: block;
  font-size: 18px;
  color: #626262;
  margin-bottom: 15px;
}
.login-content form input {
  display: block;
  width: 100%;
  height: 50px;
  border: 1px solid #495878;
  border-radius: 4px;
  padding-left: 10px;
  font-size: 18px;
  color: #626262;
}
.btn-pass-visible {
  position: absolute;
  top: 56px;
  right: 10px;
  background: transparent;
  border: none;
  cursor: pointer;
}
.login-content form a {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: #309ed9;
}
.login-content form button[type="submit"] {
  display: block;
  width: 100%;
  height: 60px;
  background: #0d9648;
  border: none;
  border-radius: 8px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
  font-size: 20px;
  font-weight: bold;
  font-family: "Titillium Web";
  color: #fff;
  margin-top: 45px;
  text-transform: uppercase;
  cursor: pointer;
}
.login-content form .secondary {
  display: block;
  width: 100%;
  height: 60px;
  background: #fff;
  border: 1px solid #009d42;
  border-radius: 5px;
  font-size: 20px;
  font-weight: bold;
  font-family: "Titillium Web";
  color: #0b793a;
  margin-top: 24px;
  text-transform: uppercase;
  margin-bottom: 60px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .login-content form button[type="submit"] {
    margin-top: 24px;
  }
  .login-content form .secondary {
    margin-top: 16px;
  }
}
.recuperacao-senha form button[type="submit"] {
  margin-top: 0;
}
.login-content form span.error,
.cadastro form span.error,
.contato form span.error {
  display: block;
  margin-top: 2px;
  color: red;
  font-size: 16px;
  text-align: right;
  font-family: "Roboto", sans-serif;
}
.login-content form input.error,
.cadastro form select.error,
.cadastro form input.error {
  border-color: red;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999999;
  opacity: 0;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.7);
}
.modal.active {
  opacity: 1;
  pointer-events: all;
  transition: all 0.5s ease-in-out;
}
.modal.disabled {
  transition: all 0.5s ease-in-out;
}
.content {
  position: relative;
  margin: 20px;
  width: 100%;
  max-width: 500px;
  background: #fff;
  border-radius: 4px;
  overflow: hidden;
  transform: translate(0, calc(-50vh - 50%));
  transition: all 0.4s ease-in-out;
  outline: 1px solid transparent;
}
.modal.active .content {
  transform: translate(0);
  transition: all 0.4s ease-in-out;
  transition-delay: 0.4s;
  z-index: 9999;
  background: #fff;
  border: none;
}
.modal .close {
  position: absolute;
  top: 0px;
  right: 0px;
  text-decoration: none;
  padding: 22px 22px;
  color: #fff;
  font-weight: 800;
  transition: all 0.3s ease-in-out;
  z-index: 1;
  border: none;
  background: rgba(0, 0, 0, 0.1);
  text-align: center;
  cursor: pointer;
  border-radius: 0 4px 0 0;
}
.modal .close:hover {
  color: #fff;
  background: rgba(0, 0, 0, 0.2);
}
.modal .content h3 {
  padding: 20px;
  display: block;
  text-align: center;
  border-bottom: 1px solid #e0e0e0;
  text-transform: uppercase;
  background: #0d9648;
  color: #fff;
  border-radius: 4px 4px 0 0;
}
.modal-area {
  padding: 20px;
}
.modal-area p {
  margin-bottom: 15px;
  font-size: 16px;
  text-align: center;
}
.modal-area p:last-child {
  margin-bottom: 0;
}
.modal-area p span {
  font-weight: 700;
  font-size: 18px;
  color: #0d9648;
}
.modal-area p a {
  display: none;
  font-size: 20px;
  text-transform: uppercase;
  background: #0d9648;
  border-radius: 5px;
  font-weight: 600;
  color: #fff;
  text-decoration: none;
  height: 60px;
  text-align: center;
  line-height: 55px;
}
.modal .btn-modal {
  display: block;
  width: 120px;
  height: 32px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;
  color: #002b63;
  text-align: center;
  text-decoration: none;
  margin-left: auto;
  cursor: pointer;
  background: linear-gradient(-70deg, #ffd400, #fba200);
}
.modal .btn-modal:disabled {
  width: 140px;
  opacity: 0.6;
  cursor: wait;
}
.modal-area p a i {
  display: inline-block;
  vertical-align: middle;
  padding-top: 10px;
  padding-right: 10px;
}
.modal-area p a i img {
  width: 25px;
  height: auto;
}
#balancetes ul {
  list-style: none;
}
#balancetes ul li {
  margin-bottom: 20px;
}
#balancetes ul li a {
  color: #0d9648;
}

#noizdocuments ul {
  list-style: none;
}

#noizdocuments ul li {
  margin-bottom: 20px;
}
#noizdocuments ul li a {
  color: #0d9648;
  vertical-align: text-top;
}

@media (max-width: 768px) {
  .container {
    margin-left: 15px;
    margin-right: 15px;
  }
  .header .container {
    margin: 0;
  }
  .header-logo img {
    width: 100px;
    height: auto;

    margin-left: 6px;
  }
  .header nav ul li a {
    height: 60px;
    padding: 0px 8px;
    font-size: 12px;
  }
  .header .menu-2 {
    width: 100%;
    border-top: 1px solid rgba(112, 112, 112, 0.2);
  }
  .header .menu-2 li {
    width: 33%;
  }
  .header .menu-2 li a {
    flex-direction: column;
    justify-content: center;
  }
  .header .menu-2 svg {
    margin-right: 0px;
    margin-bottom: 4px;
  }

  /* Subtitulos */
  .subtitulo-verde,
  .subtitulo-branco {
    font-size: 24px;
  }
  /* Wave */
  .wave-blue {
    background-image: url(../images/wave-blue-mobile.png);
    width: 100%;
    height: 110px;
  }
  /* Index */
  .banner {
    background-image: url(../images/bg3-index-mobile.png);
    background-position: center -36px;
    width: 100%;
    height: 380px;
    background-size: cover;
  }
  /* Seja */
  .seja {
    width: 100%;
    height: auto;
    min-height: 185px;
    padding: 20px 20px 17px;
  }
  .seja .seja-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    margin: 0;
  }
  .seja .seja-content h2 {
    text-align: left;
    letter-spacing: -0.55px;
    font-size: 22px;
    font-weight: 600;
    color: #002b63;
    text-transform: uppercase;
  }
  .seja .seja-content h2 strong {
    font-weight: 900;
    text-transform: uppercase;
    font-size: 22px;
    letter-spacing: -0.55px;
  }
  .seja .seja-content p {
    font-size: 15px;
    font-weight: 400;
    text-align: left;
    color: #002b63;
    line-height: 20px;
    margin-top: 10px;
  }
  .seja .seja-content > a {
    display: inline-block;
    font-weight: 900;
    color: #007432;
  }

  .seja .seja-content .btn-gradient {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 336px;
    height: 45px;
    margin: 0;
    margin-top: 10px;
    float: none;
  }
  .seja .seja-content .btn-gradient p {
    pointer-events: none;
    font-size: 15px;
    line-height: 20px;
    font-weight: 400;
    color: #fff;
    margin: 0;
    text-align: center;
    bottom: 0;
    left: 0;
  }
  .seja .seja-content .btn-gradient span {
    font-size: 20px;
    line-height: 20px;
    font-weight: 700;
  }
  .projeto {
    margin-top: -3px;
    height: auto;
    padding-bottom: 40px;
  }
  .projeto-content.container {
    width: calc(100% - 40px);
  }
  .projeto .projeto-foto-container {
    width: 100%;
  }
  .projeto .projeto-foto-container img {
    width: 100%;
  }
  .projeto .projeto-itens:nth-child(2) {
    margin-top: 20px;
    padding-left: 0px;
  }
  .projeto .projeto-itens:nth-child(2) h3 {
    font-size: 24px;
  }
  .projeto .projeto-itens:nth-child(2) p {
    font-size: 16px;
    line-height: 24px;
  }
  .missao .missao-itens {
    width: 100%;
    margin-left: 0;
    margin-bottom: 15px;
  }
  .missao .missao-itens p {
    font-size: 16px;
    color: #fff;
  }
  .regiao.container {
    width: calc(100% - 40px);
  }
  .regiao {
    padding-top: 40px;
  }
  .regiao .regiao-itens:nth-child(1) {
    padding-right: 0;
  }
  .regiao .regiao-itens:nth-child(1) p {
    font-size: 16px;
  }
  .regiao .regiao-itens:nth-child(2) p {
    font-size: 14px;
    text-transform: uppercase;
  }
  .regiao .regiao-itens:nth-child(2) {
    margin-top: 20px;
  }
  .regiao .regiao-itens:nth-child(2) .regiao-itens-foto {
    background: url(../images/foto-regiao-mobile.jpg) no-repeat center;
    width: calc(100% + 30px);
    margin-left: -15px;
    border-radius: 0px;
    background-size: cover;
    height: 400px;
    padding-top: 160px;
  }
  .regiao .regiao-itens:nth-child(2) .regiao-itens-foto h3 {
    display: inline-block;
    font-size: 50px;
    margin-bottom: 0;
    padding-left: 5px;
  }
  .regiao .regiao-itens:nth-child(2) .regiao-itens-foto h4 {
    display: inline-block;
    float: none;
    font-size: 50px;
    line-height: 50px;
  }
  .regiao .regiao-itens:nth-child(2) .regiao-itens-foto h5 {
    margin-top: -55px;
  }
  .vaquinha {
    margin-top: 24px;
  }
  .vaquinha.desk {
    display: none;
  }
  .vaquinha.mobile {
    display: block;
  }
  .vaquinha.mobile .btn-gradient-amarelo {
    font-size: 14px;
    width: 100%;
    float: none;
  }
  .iniciativas {
    margin-top: 32px;
  }
  .index .seja-socio-content-mobile h2 {
    font-size: 28px;
  }
  .index .seja-socio-content-mobile p {
    font-size: 16px;
    margin-bottom: 15px;
  }
  .index .seja-socio-content-mobile span {
    margin-bottom: 20px;
  }
  /* Sócio Transformador */
  .socio-transformador.container {
    width: calc(100% - 40px);
  }
  .socio-transformador {
    margin-top: 30px;
  }
  .socio-transformador p {
    font-size: 16px;
    line-height: 22px;
  }
  .alcancado.container {
    width: calc(100% - 40px);
  }
  .iniciativas.container {
    width: calc(100% - 40px);
  }
  .iniciativas-box {
    padding: 15px;
  }
  .iniciativas-box-content p {
    height: 140px;
  }
  .seja-socio {
    text-align: left;
  }
  .seja-socio-content-desk {
    display: none;
  }
  .seja-socio-content-mobile.container {
    width: calc(100% - 40px);
  }
  .seja-socio-content-mobile {
    display: block;
  }
  .seja-socio-content-mobile h2 {
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  .seja-socio-content-mobile span {
    margin-top: 0;
  }
  .seja-socio-content-mobile p {
    width: 100%;
    margin-top: 10px;
  }
  .seja-socio .btn-gradient-amarelo {
    width: 100%;
  }
  .post-midias-box-content h3 {
    color: #00527b;
  }
  .post-midias-box-content span {
    color: #00527b;
  }
  .share {
    margin-top: 80px;
  }
  .share h2 {
    text-transform: uppercase;
    font-family: "Titillium Web", sans-serif;
    color: #0d9648;
    margin-bottom: 15px;
  }
  .share ul li {
    margin: 0 5px;
  }
  .share ul li a img {
    width: 40px;
  }
  .parceiros {
    margin-top: -5px;
  }
  .parceiros-content.container {
    width: calc(100% - 40px);
  }
  .parceiros ul {
    display: block;
    text-align: center;
  }
  .parceiros ul li {
    margin: 0 15px 20px 15px;
  }
  .parceiros ul li:nth-child(1) {
    margin-left: 20px;
  }
  .parceiros ul li a img {
    width: 145px;
  }
  .contato {
    margin-bottom: -5px;
    padding: 60px 0 0;
  }
  .contato form {
    padding-right: 0px;
  }
  .contato div div {
    padding-right: 0;
  }
  .contato button {
    width: 100%;
  }
  .contato iframe {
    margin-top: 40px;
    width: 100%;
    height: 450px;
    width: calc(100% + 30px);
    margin-left: -15px;
  }
  footer {
    text-align: center;
    padding: 40px 0 0;
  }
  footer .col-2::before,
  footer .col-3::before {
    display: none;
  }
  footer .col-2,
  footer .col-3 {
    margin-top: 45px;
  }
  footer .col-2 ul li {
    border-top: 1px solid rgba(255, 255, 255, 0.5);
    padding: 20px;
    margin-bottom: 0px;
  }
  footer .col-2 ul li:last-child {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  }
  footer .col-3 h3 {
    margin-bottom: 30px;
  }
  footer .col-3 ul li i {
    display: block;
    margin-right: 0px;
    margin-bottom: 10px;
  }
  footer .col-3 ul li span {
    margin-bottom: 20px;
  }
  /* Page Iniciativas */
  .page-iniciativas .iniciativas {
    margin-top: 40px;
  }
  .herois-portelinha.container {
    width: calc(100% - 40px);
  }
  .herois-portelinha h2 {
    font-size: 24px;
  }
  .herois-portelinha p {
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    line-height: 24px;
  }
  .separador {
    display: none;
  }
  .acoes {
    margin-top: 25px;
  }
  .acoes.container {
    width: calc(100% - 40px);
  }
  .acoes h2 {
    font-size: 24px;
  }
  .acoes p {
    font-size: 16px;
    padding-right: 0;
    line-height: 24px;
    margin-bottom: 25px;
  }
  .acoes a {
    width: 100%;
  }
  .acoes img {
    display: none;
  }
  .seja-socio .btn-sem-fundo {
    width: 100%;
    margin-top: 15px;
  }
  .page-iniciativas .seja-socio-content-mobile p {
    margin-bottom: 10px;
  }
  .page-iniciativas .seja-socio-content-mobile span {
    margin-bottom: 15px;
  }
  /* Page detalhes iniciativa */
  .detalhes-iniciativa {
    padding-top: 0px;
  }
  .detalhes-iniciativa-content.container {
    width: calc(100% - 40px);
  }
  .banner-detalhes-iniciativa-mobile {
    display: block;
    background: url(../images/bg-caixote-mobile.jpg) no-repeat center;
    background-size: cover;
    width: 100%;
    height: 200px;
  }
  .detalhes-socio {
    padding-top: 30px;
  }
  .bg-aulas-de-idiomas {
    background: url(../images/bg-aulas-idiomas-mobile.jpg) no-repeat center;
    background-size: cover;
  }
  .bg-aulas-de-reforco {
    background: url(../images/bg-aulas-reforco-mobile.jpg) no-repeat center;
    background-size: cover;
  }
  .bg-gradua-noiz {
    background: url(../images/bg-gradua-noiz-mobile.jpg) no-repeat center;
    background-size: cover;
  }
  .bg-atendimento-medico {
    background: url(../images/bg-atendimento-medico-mobile.jpg) no-repeat center;
    background-size: cover;
  }
  .bg-atendimento-peicologico {
    background: url(../images/bg-atendimento-psico-mobile.jpg) no-repeat center;
    background-size: cover;
  }
  .bg-sessao-cinema {
    background: url(../images/bg-sessao-cinema-mobile.jpg) no-repeat center;
    background-size: cover;
  }
  .bg-aula-teatro {
    background: url(../images/bg-aula-teatro-mobile.jpg) no-repeat center;
    background-size: cover;
  }
  .bg-neuropediatrico {
    background: url(../images/bg-neuropediatrico-mobile.jpg) no-repeat center;
    background-size: cover;
  }
  .bg-cursos-profissionalizantes {
    background: url(../images/bg-cursos-profissionalizantes-mobile.jpg)
      no-repeat center;
    background-size: cover;
  }
  .detalhes-iniciativa-content {
    margin-top: 40px;
  }
  .detalhes-iniciativa-content p {
    padding-right: 0;
    font-size: 16px;
  }
  .detalhes-iniciativa-content img {
    display: none;
  }
  .galeria {
    margin-top: 40px;
  }
  .galeria-center {
    width: 100%;
  }
  .galeria.container {
    width: calc(100% - 40px);
  }
  .galeria h2 {
    margin-bottom: 20px;
  }
  .galeria .btn-sem-fundo-verde {
    width: 100%;
  }
  /* .secondary-header */
  .secondary-header .container {
    margin: 0;
  }
  .secondary-header .header-logo {
    width: 52px;
    margin: 0px;
    overflow: hidden;
  }
  .secondary-header .header-title {
    font-size: 14px;
    margin: 0px auto 0px 12px;
  }
  .secondary-header .header-close {
    height: 70px;
    padding: 0px 22px;
  }
  .secondary-header .header-close svg {
    width: 16px;
    height: 16px;
  }
  /* Cadastro */
  .form-cadastro.container {
    width: calc(100% - 40px);
  }
  .form-cadastro {
    margin-top: 40px;
  }
  .form-cadastro p {
    font-size: 16px;
    margin-top: 15px;
    margin-bottom: 40px;
  }

  .form-cadastro form label {
    font-size: 16px;
    margin-bottom: 8px;
  }
  .form-cadastro .grid_3 {
    width: 49%;
    margin-left: 2%;
  }
  .form-cadastro .grid_3:nth-child(1) {
    margin: 0;
  }
  .form-cadastro .grid_3:nth-child(3) {
    width: 100%;
  }
  .form-cadastro .help-message {
    display: block;
    float: none;
    margin: 0 !important;
  }
  /* Sucesso */
  .sucesso.container {
    width: calc(100% - 40px);
  }
  .sucesso {
    margin-top: 40px;
  }
  .detalhes.container {
    width: calc(100% - 40px);
  }
  .detalhes-content div {
    width: 100%;
  }
  .detalhes-content div p {
    font-size: 14px;
  }
  .detalhes-content div a {
    font-size: 14px;
  }
  .credencial.container {
    width: calc(100% - 40px);
  }
  .credencial-content {
    background: url(../images/bg-credencial-mobile.png) no-repeat center;
    background-size: cover;
    width: 100%;
    height: 350px;
    padding-top: 20px;
  }
  .credencial-content h3 {
    font-size: 19px;
  }
  .credencial-content img {
    width: 160px;
  }
  .credencial-content > p {
    font-size: 16px;
  }
  .btn-cancelar {
    margin-top: 35px;
  }
  .credencial-share p {
    width: 70%;
    text-align: center;
    margin: 0 auto 20px auto;
    font-size: 16px;
    line-height: 24px;
  }
  .credencial-share h3 {
    text-align: center;
    font-size: 24px;
    color: #0d9648;
    font-weight: bold;
    margin-bottom: 15px;
  }
  .credencial-share ul {
    text-align: center;
  }
  .credencial-share ul li {
    margin-right: 10px;
  }
  .credencial-share ul li a img {
    width: 40px;
  }
  /* Login */
  .login-content {
    margin-top: 40px;
  }
  .login-content form {
    width: auto;
    margin: 40px 20px 0px;
  }
  .login-content h2 {
    width: 270px;
    margin: 0 auto;
  }
  .login-content form label {
    margin-bottom: 10px;
  }
  .btn-pass-visible {
    top: 52px;
  }
  .cadastro form span.error,
  .login-content form span.error {
    font-size: 14px;
  }
  .login-content form a {
    margin-bottom: 20px;
  }
}
@media (max-width: 320px) {
  /* Index */
  .missao .missao-itens {
    height: 420px;
  }
  .projeto .projeto-itens:nth-child(2) p {
    font-size: 14px;
    line-height: 18px;
  }
  .missao .missao-itens p {
    font-size: 14px;
  }
  .regiao .regiao-itens:nth-child(2) .regiao-itens-foto h5 {
    margin-top: -40px;
    font-size: 100px;
  }
  .vaquinha .vaquinha-content h2 {
    font-size: 36px;
  }
  .alcancado .box-content {
    padding: 20px;
  }
  .alcancado .box-content p {
    font-size: 14px;
  }
  .barra-prog p {
    font-size: 14px;
  }
  .iniciativas-box {
    padding: 10px;
  }
  .iniciativas-box-content {
    height: 500px;
  }
  .iniciativas-box-content p {
    font-size: 14px;
  }
  .btn-iniciativas {
    bottom: 130px;
  }
  .post-midias {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .share h2 {
    font-size: 36px;
  }
  .parceiros ul li {
    margin: 0 10px 20px 10px;
  }
  .parceiros ul li a img {
    width: 100px;
  }
  .contato button {
    width: 100%;
  }
  /* Page Iniciativas */
  .herois-portelinha iframe {
    height: 230px;
  }
  /* Sucesso */
  .sucesso > div > p {
    font-size: 14px;
    line-height: 20px;
  }
  .detalhes {
    margin-bottom: 20px;
  }
  .detalhes-content div {
    padding: 15px 15px;
  }
  .detalhes-content div p {
    font-size: 11px;
  }
  .detalhes-content div a {
    font-size: 12px;
  }
  .credencial-content {
    background-size: 110% auto;
    padding-top: 40px;
  }
  .credencial-content h3 {
    font-size: 16px;
  }
  .credencial-content img {
    width: 120px;
  }
  .credencial-content > p {
    font-size: 14px;
  }
  .credencial-share p {
    width: 80%;
    line-height: 20px;
    font-size: 14px;
  }
  /* Login */
  .login-content h2 {
    width: 90%;
  }
}
